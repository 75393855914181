import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import "./style.css";

const LinksPlatforms = () => {
  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <section className="gauto-find-area">
      <Container>
        <Row className="justify-content-md-center">
          <Col md={8}>
            <div className="find-box">
              <Row className="align-items-center">
                <Col md={12}>
                  <div className="find-form">
                      <Row>
                        <Col md={12}>
                          <h2 className="text-center mb-3">
                            Ingrese a Nuestras Plataformas
                          </h2>
                        </Col>
                      </Row>
                      <Row className="justify-content-center">
                        <Col md={4}>
                          <p>
                            <a href="http://enterprise.itgsat.com" target="ent" className="gauto-btn gauto-btn-lnk mb-3">
                               Enterprise
                            </a>
                          </p>
                        </Col>
                        <Col md={4}>
                          <p>
                            <a href="http://flotas.itgsat.com/?lang=es" target="pre" className="gauto-btn gauto-btn-lnk mb-3">
                               Premium
                            </a>
                          </p>
                        </Col>
                      </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default LinksPlatforms;
