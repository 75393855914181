import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaSkype,
  FaPaperPlane,
} from "react-icons/fa";

import logo from "../../img/itgsat_logodark.png";

import "./style.css";

const Footer = () => {
  const { t } = useTranslation();

  const onClick = (e) => {
    e.preventDefault();
  };

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <footer className="gauto-footer-area">
      <div className="footer-top-area">
        <Container>
          <Row>
            <Col lg={4}>
              <div className="single-footer">
                <div className="footer-logo">
                  <Link to="/">
                    <img src={logo} alt="footer-logo" />
                  </Link>
                </div>
                <p>
                Somos una empresa de gestión de flotas y seguridad vehicular.
                Nos dedicamos al monitoreo y rastreo satelital en todo el Perú.
                </p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="single-footer quick_links">
                <h3>Enlaces</h3>
                <ul className="quick-links">
                  <li>
                    <Link to="/" onClick={onClick}>
                      {" "}
                      Inicio
                    </Link>
                  </li>
                  <li>
                    <Link to="/nosotros" onClick={onClick}>
                      Nosotros
                    </Link>
                  </li>
                  <li>
                    <Link to="/servicios" onClick={onClick}>
                      Servicios
                    </Link>
                  </li>
                  <li>
                    <Link to="/trabaja" onClick={onClick}>
                      Trabaja con nosotros
                    </Link>
                  </li>
                </ul>
                <ul className="quick-links">
                  <li>
                    <Link to="/contacto" onClick={onClick}>
                      Contacto
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={4}>
              <div className="single-footer">
                <h3>Ubiquenos en</h3>
                <p>
                  Cal. Las Esmeraldas 260 Dpto. 201, Urb. Santa Ines, <span>Trujillo, La Libertad, Perú</span>
                </p>
                <ul>
                  <li>Teléfonos: 943776822 - 946531873 </li>
                  <li>Correo: administracion@itgsat.com</li>
                  <li>Horario de atención: <br/>LUN - VIE:  9:00 am - 1:00 pm / 3:00 pm - 6:00 pm<br/>SAB: 9:00 am - 1:00 pm</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-bottom-area">
        <Container>
          <Row>
            <Col md={6}>
              <div className="copyright">
                <p>
                  Diseñado por{" "}
                  <a href="https://www.joranve.com" target="jrv">
                    Joranve Networks
                  </a>
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div className="footer-social">
                <ul>
                  <li>
                    <Link to="/" onClick={onClick}>
                      <FaFacebookF />
                    </Link>
                  </li>
                  <li>
                    <Link to="/" onClick={onClick}>
                      <FaTwitter />
                    </Link>
                  </li>
                  <li>
                    <Link to="/" onClick={onClick}>
                      <FaLinkedinIn />
                    </Link>
                  </li>
                  <li>
                    <Link to="/" onClick={onClick}>
                      <FaSkype />
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
