import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaFacebook,
  FaLinkedin,
  FaPaperPlane,
  FaPinterest,
  FaSkype,
  FaTwitter,
  FaVimeo,
  FaEnvelope,
  FaPhone
} from "react-icons/fa";

import "./style.css";

const Contact = () => {

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const onClick = (e) => {
    e.preventDefault();
  };

  return (
    <section className="gauto-contact-area section_70">
      <Container>
        <Row>
          <Col lg={7} md={6}>
            <div className="contact-left">
              <h3>Ponerse en contacto</h3>
              <p>Déjenos su mensaje y en breve nos comunicaremos.</p>
              <form onSubmit={SubmitHandler}>
                <Row>
                  <Col md={6}>
                    <div className="single-contact-field">
                      <input type="text" placeholder="Nombre" />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-contact-field">
                      <input
                        type="email"
                        placeholder="Correo electrónico"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <div className="single-contact-field">
                      <input
                        type="text"
                        placeholder="Asunto"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="single-contact-field">
                      <input type="tel" placeholder="Teléfono" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className="single-contact-field">
                      <textarea
                        placeholder="Escriba su mensaje aquí"
                        defaultValue={""}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className="single-contact-field">
                      <button type="submit" className="gauto-theme-btn">
                        <FaPaperPlane /> Enviar mensaje
                      </button>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
          <Col lg={5} md={6}>
            <div className="contact-right">
              <h3>Información de contacto </h3>
              <div className="contact-details">
                <p>
                  <i className="fa fa-map-marker" />Cal. Las Esmeraldas 260 Dpto. 201, Urb. Santa Ines, Trujillo, La Libertad, Perú{" "}
                </p>
                <div className="single-contact-btn">
                  <h4>Area Administrativa</h4>
                  <a href="mailto:administracion@itgsat.com"><FaEnvelope /> administracion@itgsat.com</a>
                </div>
                <div className="single-contact-btn">
                  <h4>{" "}</h4>
                  <a href="tel:943776822"><FaPhone /> 943 776 822</a>
                </div>
                <div className="single-contact-btn">
                  <h4>Area Operaciones</h4>
                  <a href="mailto:operaciones@itgsat.com"><FaEnvelope /> operaciones@itgsat.com</a>
                </div>
                <div className="single-contact-btn">
                  <h4>{" "}</h4>
                  <a href="tel:949949450"><FaPhone /> 949 949 450</a>
                </div>
                <div className="social-links-contact">
                  <h4>Síganos</h4>
                  <ul>
                    <li>
                      <Link to="/" onClick={onClick}>
                        <FaFacebook />
                      </Link>
                    </li>
                    <li>
                      <Link to="/" onClick={onClick}>
                        <FaTwitter />
                      </Link>
                    </li>
                    <li>
                      <Link to="/" onClick={onClick}>
                        <FaLinkedin />
                      </Link>
                    </li>
                    <li>
                      <Link to="/" onClick={onClick}>
                        <FaPinterest />
                      </Link>
                    </li>
                    <li>
                      <Link to="/" onClick={onClick}>
                        <FaSkype />
                      </Link>
                    </li>
                    <li>
                      <Link to="/" onClick={onClick}>
                        <FaVimeo />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact;
