import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  FaDesktop,
  FaSatelliteDish,
  FaLocationArrow,
  FaCode
} from "react-icons/fa";

import img1 from "../../img/city-transport.png";
import img2 from "../../img/airport-transport.png";
import img3 from "../../img/hospital-transport.png";
import img4 from "../../img/wedding-ceremony.png";
import img5 from "../../img/hotel-transport.png";
import img6 from "../../img/luggege-transport.png";

import "./style.css";

const Service = () => {
  const { t } = useTranslation();

  const settings = {
    dots: true,
    arrows: false,
    speed: 1200,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          infinite: true,
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          infinite: true,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <section className="gauto-service-area section_70">
      <Container>
        <Row>
          <Col md={12}>
            <div className="site-heading">
              <h4>puede encontrar</h4>
              <h2>Algunos de nuestros servicios</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Slider className="service-slider" {...settings}>
              <div className="slide">
                <div className="single-service">
                  <div className="service-icon">
                    <FaDesktop/>
                  </div>
                  <div className="service-text">
                    <Link to="/servicios">
                      <h3>Plataformas a su medida</h3>
                    </Link>
                    <p>
                    Contamos con dos plataformas acorde a tu necesidad con funcionalidades fáciles de manejar 
                    desde cualquier dispositivo para que tengas control total sobre tu flota.
                    </p>
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="single-service">
                  <div className="service-icon">
                    <FaSatelliteDish />
                  </div>
                  <div className="service-text">
                    <Link to="/servicios">
                      <h3>Rastreo satelital 24/7</h3>
                    </Link>
                    <p>
                    Estamos comprometidos con la seguridad de sus activos, manejamos protocolos de acción 
                    preestablecidos ante cualquier amenaza o confirmación de robo.
                    </p>
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="single-service">
                  <div className="service-icon">
                    <FaLocationArrow />
                  </div>
                  <div className="service-text">
                    <Link to="/servicios">
                      <h3>Migración de equipos GPS</h3>
                    </Link>
                    <p>
                    Si cuenta con un dispositivo GPS te ayudamos a migrar a nuestro servicio, 
                    solo contáctenos y lo ayudaremos, será el primer paso para que tenga el control total de su flota.
                    </p>
                  </div>
                </div>
              </div>
              <div className="slide">
                <div className="single-service">
                  <div className="service-icon">
                    <FaCode />
                  </div>
                  <div className="service-text">
                    <Link to="/servicios">
                      <h3>Desarrollo de módulos de retransmisión</h3>
                    </Link>
                    <p>
                      Risus commodo maecenas accumsan lacus vel facilisis. Dorem
                      ipsum dolor consectetur adipiscing elit.
                    </p>
                  </div>
                </div>
              </div>
            </Slider>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Service;
