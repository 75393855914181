import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { FaPhoneAlt } from "react-icons/fa";

import img1 from "../../img/about-page.jpg";
import img2 from "../../img/about-ventajas.jpg";

import "./style.css";

const AboutPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="about-page-area section_70">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="about-page-left">
                <h3>¿Quienes somos?</h3>
                <p>
                Somos una empresa peruana orientada a brindar soluciones GPS destacada por su compromiso con sus colaboradores, 
                clientes y medio ambiente. Contamos con más de 9 años en el sector, cubriendo tus necesidades en todo el Perú, 
                ofreciendo soluciones GPS para localización, rastreo vehicular y administración de flotas a empresas y 
                vehículos particulares las 24 horas al día, los 365 días del año ejecutando protocolos de acción preestablecidos frente a alarmas, 
                sospecha o confirmación de robos.
                </p>
                <p>
                Nuestras soluciones para la administración de flotas y seguridad de activos utilizan plataformas modernas y 
                altamente tecnológicas con funciones que puedes manejar fácilmente desde cualquier PC o dispositivo mobil, 
                brindándote respuesta a tus consultas en cualquier momento del día. 
                Nuestra misión es Maximizar el rendimiento de tu flota de una manera inteligente y tecnológica aportando mayor seguridad, 
                e incrementando tu eficiencia y productividad.
                </p>
                <div className="about-page-call">
                  <div className="page-call-icon">
                    <FaPhoneAlt />
                  </div>
                  <div className="call-info">
                    <p>¿Necesita alguna ayuda?</p>
                    <h4>
                      <a href="tel:943776822">943 776 822</a>
                    </h4>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="about-page-right">
                <img src={img1} alt="quienes somos" />
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col lg={6} className="mb-4">
            <img src={img2} alt="Ventajas" />
            </Col>
            <Col lg={6}>
            <div className="about-page-left">
              <h3>Nuestras Ventajas</h3>
              <Row>
              <Col md={10}>
                <div className="about-ventajas">
                    <div>
                    <h4 className="mt-3">Gestión Eficiente del Tiempo</h4>
                    <p>
                      Disminuimos su tiempo en la ejecución de tareas repetitivas. 
                      El tiempo es dinero para su empresa, por lo tanto no se puede malgastar.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={10}>
                <div className="about-ventajas">
                  <div>
                    <h4 className="mt-3">Control Total Sobre Su Flota</h4>
                    <p>
                      Disminuimos su tiempo en la ejecución de tareas repetitivas. 
                      El tiempo es dinero para su empresa, por lo tanto no se puede malgastar.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={10}>
                <div className="about-ventajas">
                  <div>
                    <h4 className="mt-3">Reducción de Costos</h4>
                    <p>
                      Disminuimos su tiempo en la ejecución de tareas repetitivas. 
                      El tiempo es dinero para su empresa, por lo tanto no se puede malgastar.
                    </p>
                  </div>
                </div>
              </Col>

              </Row>
            </div>
            </Col>
          </Row>
        </Container>
      </section>

    </>
  );
};

export default AboutPage;
