import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import Img1 from "../../img/about.png";
import "./style.css";
const About = () => {
  return (
    <section className="gauto-about-area section_70">
      <Container>
        <Row>
          <Col lg={6}>
            <div className="about-left">
              <h4>Sobre Nosotros</h4>
              <h2>Bienvenido a ITG SAT</h2>
              <p>Desde 2013, nos hemos comprometido en brindar un buen servicio en el monitoreo de vehículos,
                logrando ser una de las empresas líderes en el rubro.</p>
              <div className="about-list">
                <ul>
                  <li>
                    <span>
                      <FaCheck />
                    </span>
                    Nosotros somos una empresa de confianza
                  </li>
                  <li>
                    <span>
                      <FaCheck />
                    </span>
                    Contamos con plataformas según sus necesidades
                  </li>
                  <li>
                    <span>
                      <FaCheck />
                    </span>
                    Trabajamos con las mejores marcas de equipos gps
                  </li>
                  <li>
                    {" "}
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="about-right">
              <img src={Img1} alt="car" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default About;
